import assert from "assert";

export function debounce<T extends Function>(func: T, timeoutMs: number): () => void {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func(...args) }, timeoutMs);
    };
}

export function retry<T>(func: () => Promise<T>, retryDelayMs: number = 100, maxRetries: number = 10): Promise<T> {
    return new Promise(async (res, err) => {
        const retryFunc = async (attempt: number): Promise<void> => {
            try {
                return res(await func());
            } catch (error) {
                if (attempt >= maxRetries) {
                    return err(error);
                }

                setTimeout(async () => retryFunc(attempt + 1), retryDelayMs);
            }
        };
        await retryFunc(0);
    });
}

export function validateString(value: any, _default: string|undefined = undefined): string {
    if (value === undefined || value === null || value === "") {
        if (_default === undefined) {
            throw new Error("Value not defined");
        }

        return _default;
    }

    return value;
}

export function validateInt(value: any, _default: number|undefined = undefined): number {
    if (value === undefined || value === null || isNaN(value)) {
        if (_default === undefined) {
            throw new Error("Value not defined");
        }

        return _default;
    }

    return parseInt(value);
}

export function isEmpty(object: {}): boolean {
    return (Object.keys(object).length === 0);
}

export function linearInterpolateY(x: number, coords1: {x: number, y: number}, coords2: { x: number, y: number }): number {
    const deltaY = coords2.y - coords1.y;
    const deltaX = coords2.x - coords1.x;

    // Vertical line
    if (deltaX === 0) {
        return coords1.y;
    }

    return (deltaY / deltaX) * (x - coords1.x) + coords1.y;
}

export class ResolvablePromise<T> {
    private readonly resolvePromise: Promise<[(value: T | PromiseLike<T>) => void, (reason?: any) => void]>;
    private completePromise: Promise<T>|null = null;
    private resolved: boolean = false;

    public constructor() {
        // Browser/implementation agnostic way to manually resolve promise (don't have to rely on executor running inline)
        this.resolvePromise = new Promise((outerRes) => {
            this.completePromise = new Promise((innerRes, innerRej) => {
                outerRes([innerRes, innerRej]);
            });
        });
    }

    public async promise(): Promise<T> {
        await this.resolvePromise;
        assert(this.completePromise !== null);
        return this.completePromise;
    }

    public resolve(value: T): ResolvablePromise<T> {
        this.checkNotResolved();
        this.resolved = true;
        this.resolvePromise.then((res) => res[0](value));
        return this;
    }

    public reject(reason?: any): ResolvablePromise<T> {
        this.checkNotResolved();
        this.resolved = true;
        this.resolvePromise.then((res) => res[1](reason));
        return this;
    }

    public isResolved(): boolean {
        return this.resolved;
    }

    private checkNotResolved(): void {
        if (this.resolved) {
            throw new Error("Already resolved");
        }
    }
}
