import { PureComponent, ReactNode } from "react";
import logo from "../../assets/images/rgb-logo-white.svg"
import medium from "../../assets/images/medium-logo-white.svg"
import x from "../../assets/images/x-logo-white.svg"
import youtube from "../../assets/images/youtube-logo-white.svg"
import "./footer.css";

export class Footer extends PureComponent {
    public render(): ReactNode {
        return (
            <div id="footer-background">
                <div className="container" id="footer-container">
                    <section id="footer-row">
                        <div>
                            <a id="footer-logo" href="/">
                                <img alt="" src={logo}/>
                            </a>
                        </div>
                        <div id="copyright">
                            <p className="rgb-white">{"Copyright \u00A9 " + new Date().getFullYear() + " Tomilion. All Rights Reserved"}</p>
                        </div>
                        <div>
                            <a href="https://x.com/rgblisk">
                                <img alt="" src={x}/>
                            </a>
                            <a href="https://rgblisk.medium.com">
                                <img alt="" src={medium}/>
                            </a>
                            <a href="https://www.youtube.com/@rgblisk">
                                <img alt="" src={youtube}/>
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
