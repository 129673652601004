import { PureComponent, ReactNode } from "react";
import earn from "../../assets/images/earn-image.svg";
import "./earn.css";

export class Earn extends PureComponent {
    public componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#earn-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    public render(): ReactNode {
        return (
            <div className="container">
                <section id="earn-row">
                    <div id="earn-description-col" className="hidden">
                        <h2 className="rgb-pink">Earn</h2>
                        <p>Draw as much or as little as you want - every pixel counts towards your share of the proceeds when the artwork sells. <b>We'll take care of the rest!</b> From running the NFT auction to automatically distributing profits based on your contribution. Just grab your digital brush and start creating!</p>
                    </div>
                    <div id="earn-image-col" className="hidden">
                        <img src={earn} alt=""/>
                    </div>
                </section>
            </div>
        );
    }
}
