import { PureComponent, ReactNode } from "react";
import create from "../../assets/images/create-image.svg";
import "./create.css";

export class Create extends PureComponent {
    public componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#create-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    public render(): ReactNode {
        return (
            <div className="container" id="learn">
                <section id="create-row">
                    <div id="create-description-col" className="hidden">
                        <h2 className="rgb-green">Create</h2>
                        <p>Make your mark on a <b>unique timelapse NFT</b>. Every brushstroke you create is captured in real-time and stored on our blockchain. The final piece is a dynamic digital artwork that reveals its own story of creation!</p>
                    </div>
                    <div id="create-image-col" className="hidden">
                        <img src={create} alt=""/>
                    </div>
                </section>
            </div>
        );
    }
}
