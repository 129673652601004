import { PureComponent, ReactNode } from "react";
import { Link } from "react-scroll";
import ambassadors from "../../assets/images/ambassador-program.svg";
import "./ambassadors.css";

export class Ambassadors extends PureComponent {
    public componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#ambassadors-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    public render(): ReactNode {
        return (
            <div className="container">
                <section id="ambassadors-row">
                    <div id="ambassadors-description-col" className="hidden">
                        <h2 className="rgb-green">Ambassador Program</h2>
                        <p>We're looking for ambassadors to help grow our community! As an ambassador you will be entitled to earnings and other rewards! Contact us to find out more!</p>
                        <Link
                            className="rgb-button-green"
                            to="subscribe"
                            smooth={true}
                            offset={-70}
                            duration= {500}
                        >Contact Us</Link>
                    </div>
                    <div id="ambassadors-image-col" className="hidden">
                        <img src={ambassadors} alt=""/>
                    </div>
                </section>
            </div>
        );
    }
}
