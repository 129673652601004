import { Component, createRef, ReactNode, RefObject } from "react";
import roadmap from "../../assets/images/roadmap.svg";
import { debounce, linearInterpolateY } from "../../components/util/helpers";
import "./roadmap.css";

interface RoadmapState {
    rowMarginTop: string;
    imageWidth: string;
    phasePadding: string;
    phase2MarginTop: string;
    phase3MarginTop: string;
    phase4MarginTop: string;
    phase5MarginTop: string;
    fontSizeH2: string;
    fontSizeP: string;
    lineHeightP: string;
}

export class Roadmap extends Component<{}, RoadmapState> {
    private containerRef: RefObject<HTMLDivElement>;
    private handleResize: () => void = () => {
        const screenWidth = window.innerWidth;
        const containerWidth = this.containerRef.current?.clientWidth;

        if (containerWidth) {
            this.setState((state) => ({
                ...state,
                rowMarginTop: this.calculateRowMarginTop(screenWidth, containerWidth),
                imageWidth: this.calculateImageWidth(screenWidth, containerWidth),
                phasePadding: this.calculatePhasePadding(screenWidth, containerWidth),
                phase2MarginTop: this.calculatePhase2MarginTop(screenWidth, containerWidth),
                phase3MarginTop: this.calculatePhase3MarginTop(screenWidth, containerWidth),
                phase4MarginTop: this.calculatePhase4MarginTop(screenWidth, containerWidth),
                phase5MarginTop: this.calculatePhase5MarginTop(screenWidth, containerWidth),
                fontSizeH2: this.calculateFontSizeH2(screenWidth, containerWidth),
                fontSizeP: this.calculateFontSizeP(screenWidth, containerWidth),
                lineHeightP: this.calculateLineHeightP(screenWidth, containerWidth),
            }));
        }

        // Keep calling until container loads
        this.awaitContainerLoad();
    };
    private awaitContainerLoad: () => void = debounce(() => this.handleResize(), 100);

    constructor(props: {}) {
        super(props);
        this.state = {
            rowMarginTop: "",
            imageWidth: "",
            phasePadding: "",
            phase2MarginTop: "",
            phase3MarginTop: "",
            phase4MarginTop: "",
            phase5MarginTop: "",
            fontSizeH2: "",
            fontSizeP: "",
            lineHeightP: "",
        };
        this.containerRef = createRef();
    }

    public componentDidMount(): void {
        const observer03 = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.3 });
        const observer08 = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#roadmap-row .hidden");
        hiddenElements.forEach((elem) => {
            if (elem.id === "roadmap-col-mid") {
                observer03.observe(elem)
            } else {
                observer08.observe(elem)
            }
        });

        window.addEventListener("resize", this.handleResize);
        // Initialise component scaling
        this.handleResize();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);
    }

    public render(): ReactNode {
        return (
            <div ref={this.containerRef} className="container" style={{ marginTop: this.state.rowMarginTop }}>
                <div id="roadmap-row">
                    <div id="roadmap-header" className="hidden">
                        <h2 className="rgb-pink" style={{ textAlign: "center" }}>Roadmap</h2>
                    </div>
                    <section id="roadmap-content-row">
                        <div id="roadmap-col-left" style={{ paddingRight: this.state.phasePadding }}>
                            <div className="hidden">
                                <h2 className="rgb-blue" style={{ fontSize: this.state.fontSizeH2, textAlign: "right" }}>Phase 1</h2>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Official website</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Social channels</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Market research</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Initial roadmap</p>
                            </div>

                            <div className="hidden">
                                <h2 className="rgb-blue" style={{ fontSize: this.state.fontSizeH2, textAlign: "right", marginTop: this.state.phase3MarginTop }}>Phase 3</h2>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Migrate backend (Lisk L2)</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Lottery integration</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Ambassador program</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>✔ Release beta (Lisk L2)</p>
                            </div>

                            <div className="hidden">
                                <h2 className="rgb-blue" style={{ fontSize: this.state.fontSizeH2, textAlign: "right", marginTop: this.state.phase5MarginTop }}>Phase 5</h2>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>・Start NFT cycles</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>・Timelapse heatmaps (UI)</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>・Timelapse histograms (UI)</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>・Timelapse user following (UI)</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "right" }}>・Canvas moderation</p>
                            </div>
                        </div>
                        <div id="roadmap-col-mid" className="hidden">
                            <img src={roadmap} alt="" style={{ width: this.state.imageWidth }} />
                        </div>
                        <div id="roadmap-col-right" style={{ paddingLeft: this.state.phasePadding }}>
                            <div className="hidden">
                                <h2 className="rgb-blue" style={{ fontSize: this.state.fontSizeH2, textAlign: "left", marginTop: this.state.phase2MarginTop }}>Phase 2</h2>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>✔ Canvas DAO</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>✔ Timelapse aggregations</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>✔ Gallery</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>✔ Launch testnet (Lisk L1)</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>✔ Submit MVP</p>
                            </div>

                            <div className="hidden">
                                <h2 className="rgb-blue" style={{ fontSize: this.state.fontSizeH2, textAlign: "left", marginTop: this.state.phase4MarginTop }}>Phase 4</h2>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>✔ Release token (Lisk L2)</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>✔ DEX listing</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>・NFT marketplace integration</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, lineHeight: this.state.lineHeightP, textAlign: "left" }}>・Canvas stamps</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

    private calculateRowMarginTop(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1100) {
            return "-280px";
        }

        return linearInterpolateY(containerWidth, {x: 442, y: -180}, {x: 990, y: -280}) + "px";
    }

    private calculateImageWidth(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "700px";
        }

        return (0.4861111111111111 * containerWidth) + "px";
    }

    private calculatePhasePadding(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1100) {
            return "40px";
        }

        return (0.0347826086956522 * containerWidth) + "px";
    }

    private calculatePhase2MarginTop(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "260px";
        }

        if (screenWidth > 1100) {
            return linearInterpolateY(containerWidth, {x: 990, y: 180}, {x: 1440, y: 260}) + "px";
        }

        return linearInterpolateY(containerWidth, {x: 442, y: 78}, {x: 990, y: 170}) + "px";
    }

    private calculatePhase3MarginTop(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "280px";
        }

        if (screenWidth > 1100) {
            return linearInterpolateY(containerWidth, {x: 990, y: 190}, {x: 1440, y: 280}) + "px";
        }

        return linearInterpolateY(containerWidth, {x: 442, y: 84}, {x: 990, y: 190}) + "px";
    }

    private calculatePhase4MarginTop(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "280px";
        }

        if (screenWidth > 1100) {
            return linearInterpolateY(containerWidth, {x: 990, y: 190}, {x: 1440, y: 280}) + "px";
        }

        return linearInterpolateY(containerWidth, {x: 442, y: 90}, {x: 990, y: 200}) + "px";
    }

    private calculatePhase5MarginTop(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "300px";
        }

        if (screenWidth > 1100) {
            return linearInterpolateY(containerWidth, {x: 990, y: 220}, {x: 1440, y: 300}) + "px";
        }

        return linearInterpolateY(containerWidth, {x: 442, y: 90}, {x: 990, y: 210}) + "px";
    }

    private calculateFontSizeH2(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "41px";
        }

        if (screenWidth > 1100) {
            return (0.0284722222222222 * containerWidth) + "px";
        }

        return (0.0284722222222222 * containerWidth) + "px";
    }

    private calculateFontSizeP(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "22px";
        }

        if (screenWidth > 1100) {
            return (0.0152777777777778 * containerWidth) + "px";
        }

        return (0.0152777777777778 * containerWidth) + "px";
    }

    private calculateLineHeightP(screenWidth: number, containerWidth: number): string {
        if (screenWidth > 1596) {
            return "35px";
        }

        if (screenWidth > 1100) {
            return (0.0243055555555556 * containerWidth) + "px";
        }

        return (0.0243055555555556 * containerWidth) + "px";
    }
}
