import { Component, ReactNode } from "react";
import lisk from "../../assets/images/lisk-logo.png";
import { linearInterpolateY } from "../../components/util/helpers";
import "./powered_by.css";

interface PoweredByState {
    rowMarginTop: string;
    linkMarginTop: string;
}

export class PoweredBy extends Component<{}, PoweredByState> {
    public state: PoweredByState = {
        rowMarginTop: "",
        linkMarginTop: "",
    };

    private handleResize: () => void = () => {
        const screenWidth = window.innerWidth;
        this.setState((state) => ({
            ...state,
            rowMarginTop: this.calculateRowMarginTop(screenWidth),
            linkMarginTop: this.calculateLinkMarginTop(screenWidth),
        }));
    };

    public componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#powered-by-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));

        window.addEventListener("resize", this.handleResize);
        // Initialise component scaling
        this.handleResize();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);
    }

    public render(): ReactNode {
        return (
            <div className="container">
                <section id="powered-by-row" style={{ marginTop: this.state.rowMarginTop }}>
                    <div className="hidden">
                        <h4 className="rgb-black">Powered by</h4>
                        <p style={{ marginTop: this.state.linkMarginTop }}>
                            <a href="https://lisk.com">
                                <img src={lisk} alt=""/>
                            </a>
                        </p>
                    </div>
                </section>
            </div>
        );
    }

    private calculateRowMarginTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return "120px";
        }

        return linearInterpolateY(screenWidth, {x: 492, y: 80}, {x: 1100, y: 120}) + "px";
    }

    private calculateLinkMarginTop(screenWidth: number): string {
        if (screenWidth > 1596) {
            return "0px";
        }

        if (screenWidth > 1100) {
            return linearInterpolateY(screenWidth, {x: 1100, y: -20}, {x: 1596, y: 0}) + "px";
        }

        return linearInterpolateY(screenWidth, {x: 492, y: -60}, {x: 1100, y: -20}) + "px";
    }
}
