import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { Component, Fragment, ReactNode } from "react";
import { AlertProps } from "../../components/layout/alert";
import { Cookies } from "../../components/layout/cookies";
import { Footer } from "../../components/layout/footer";
import { NavBar } from "../../components/layout/navbar";
import { Ambassadors } from "./ambassadors";
import { Create } from "./create";
import { CreateCollaborateEarn } from "./create_collaborate_earn";
import { Collaborate } from "./collaborate";
import { Earn } from "./earn";
import { Header } from "./header";
import { NftMarketplace } from "./nft_marketplace";
import { PoweredBy } from "./powered_by";
import { Roadmap } from "./roadmap";
import { Subscribe } from "./subscribe";
import { Tokenomics } from "./tokenomics";
import { Win } from "./win";

interface HomeState {
    alert: AlertProps|null;
}

export class Home extends Component<any, HomeState> {
    state: HomeState = { alert: null };

    render(): ReactNode {
        return (
            <Fragment>
                <NavBar marginLeft="max(calc(50% - 540px), 0px)"
                        redirect={false}
                        alertCb={(alert: AlertProps) => this.setState((state) => ({ ...state, alert: alert }))}
                />
                <Header />
                <CreateCollaborateEarn />
                <Create />
                <Collaborate />
                <Earn />
                <NftMarketplace />
                <Win />
                <Ambassadors />
                <Tokenomics />
                <Roadmap />
                <PoweredBy />
                <Subscribe alertCb={(alert: AlertProps) => this.setState((state) => ({ ...state, alert: alert }))} />
                <Footer />
                <Cookies />
                <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          open={!!this.state.alert}
                          onClose={() => this.setState((state) => ({ ...state, alert: null }))}
                          message={this.state.alert?.message}
                          autoHideDuration={this.state.alert?.autoHideDuration}>
                    {(!this.state.alert) ? undefined : (this.state.alert.severity === "message") ? undefined :
                        <Alert severity={this.state.alert.severity}
                               variant="filled"
                               sx={{ width: "100%" }}>
                            {this.state.alert.message}
                        </Alert>
                    }
                </Snackbar>
            </Fragment>
        );
    }
}
