import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import { Component, Fragment, ReactNode} from "react";
import { Link } from "react-router-dom";
import background from "../../assets/images/gallery-background.svg";
import { ApiClient } from "../../components/clients/api_client";
import { NavBar } from "../../components/layout/navbar";
import { CANVAS_ID_KEY } from "../../components/util/common";
import { app } from "../../config/app";
import "./gallery.css";

let gallery: Gallery|null = null;

window.addEventListener("resize", () => gallery?.resize());

interface GalleryItemData {
    url: string;
    title: string;
    image: string;
}

interface GalleryState {
    loading: boolean;
    items: GalleryItemData[];
}

export class Gallery extends Component<any, GalleryState> {
    state: GalleryState = {
        loading: true,
        items: [],
    };

    public async componentDidMount(): Promise<void> {
        gallery = this;

        ApiClient.getInstance().queryTimelapses().then(async (timelapses) => {
            if (!timelapses || timelapses.total === 0) {
                this.setState({ ...this.state, loading: false });
                return;
            }

            this.setState({
                ...this.state,
                loading: false,
                items: timelapses.timelapses.filter((timelapse) => timelapse.completed).map((timelapse) => {
                    return {
                        url: `/timelapse?${CANVAS_ID_KEY}=${timelapse.canvasId}`,
                        title: timelapse.label,
                        image: `${app.API_URL}/v1/timelapses/${timelapse.canvasId}/previews/${timelapse.endBlockHeight}`,
                    };
                }),
            });
        });
    }

    public componentWillUnmount(): void {
        document.body.style.background = "";
        document.body.style.maxHeight = "";
        document.body.style.overflowY = "";
    }

    public render(): ReactNode {
        document.body.style.background = "rgba(226, 226, 255, 0.4)";

        if (this.state.items.length === 0) {
            document.body.style.maxHeight = "100vh";
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.maxHeight = "";
            document.body.style.overflowY = "";
        }

        return (
            <Fragment>
                <NavBar redirect={true} />
                <img id="gallery-page-background-image" src={background} alt="#"/>
                <div id="gallery-container">
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        <Grid container spacing={3}>
                            {(this.state.items.length === 0) ?
                                <Fragment>
                                    {[...Array(Math.floor(window.innerHeight / 245) * 3)].map((el, i) => (
                                        <Grid item xs={12} md={6} lg={4} key={i}>
                                            <div className="gallery-image-container"></div>
                                        </Grid>
                                    ))}
                                    {(this.state.loading) ? null :
                                        <div id="gallery-empty-container">
                                            <div id="discover-container">
                                                <h2>Discover our latest NFTs here!</h2>
                                            </div>
                                            <Link to="/" className="rgb-button-blue">
                                                Back to Homepage
                                            </Link>
                                        </div>
                                    }
                                </Fragment>
                                : this.state.items.map((item, index) => (
                                    <Grid item xs={12} md={6} lg={4} key={item.url}>
                                        <Slide direction="right" in={true} timeout={index * 100} mountOnEnter unmountOnExit>
                                            <div className="gallery-image-container">
                                                <Link to={item.url}>
                                                    <img
                                                        srcSet={item.image}
                                                        src={item.image}
                                                        alt={item.title}
                                                        loading="lazy"
                                                        className="gallery-image"
                                                    />
                                                </Link>
                                            </div>
                                        </Slide>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Container>
                </div>
            </Fragment>
        );
    }

    public resize(): void {
        if (this.state.items.length === 0) {
            this.forceUpdate();
        }
    }
}
