import { Component, createRef, ReactNode, RefObject } from "react";
import tokenomics from "../../assets/images/tokenomics.svg";
import { debounce, linearInterpolateY } from "../../components/util/helpers";
import "./tokenomics.css";

interface TokenomicsState {
    rowMarginTop: string;
    rowPaddingTop: string;
    rowPaddingBottom: string;
    titleMarginRight: string;
    imageMarginTop: string;
    contentRowTop: string;
    publicSaleHeaderTop: string;
    publicSaleHeaderRight: string;
    publicSaleContentTop: string;
    publicSaleContentLeft: string;
    totalSupplyTop: string;
    totalSupplyLeft: string;
    allocationTop: string;
    allocationLeft: string;
    allocationMarginTop: string;
    fontSizeH3: string;
    fontSizeP: string;
    heightP: string;
}

export class Tokenomics extends Component<{}, TokenomicsState> {
    private imageRef: RefObject<HTMLImageElement>;
    private handleResize: () => void = () => {
        const screenWidth = window.innerWidth;
        const imageWidth = this.imageRef.current?.width;

        if (imageWidth) {
            this.setState((state) => ({
                ...state,
                fontSizeH3: this.calculateFontSizeH3(imageWidth),
                fontSizeP: this.calculateFontSizeP(imageWidth),
            }));
            this.setState((state) => ({
                ...state,
                heightP: this.calculateHeightP(state.fontSizeP),
            }));
        } else {
            // Keep calling until image loads (and fall-through to other calculations)
            this.awaitImageLoad();
        }

        this.setState((state) => ({
            ...state,
            rowMarginTop: this.calculateRowMarginTop(screenWidth),
            rowPaddingTop: this.calculateRowPaddingTop(screenWidth),
            rowPaddingBottom: this.calculateRowPaddingBottom(screenWidth),
            titleMarginRight: this.calculateTitleMarginRight(screenWidth),
            imageMarginTop: this.calculateImageMarginTop(screenWidth),
            contentRowTop: this.calculateContentRowTop(screenWidth),
            publicSaleHeaderTop: this.calculatePublicSaleHeaderTop(screenWidth),
            publicSaleHeaderRight: this.calculatePublicSaleHeaderRight(screenWidth),
            publicSaleContentTop: this.calculatePublicSaleContentTop(screenWidth),
            publicSaleContentLeft: this.calculatePublicSaleContentRight(screenWidth),
            totalSupplyTop: this.calculateTotalSupplyTop(screenWidth),
            totalSupplyLeft: this.calculateTotalSupplyLeft(screenWidth),
            allocationTop: this.calculateAllocationTop(screenWidth),
            allocationLeft: this.calculateAllocationLeft(screenWidth),
            allocationMarginTop: this.calculateAllocationMarginTop(screenWidth),
        }));
    };
    private awaitImageLoad: () => void = debounce(() => this.handleResize(), 100);

    constructor(props: {}) {
        super(props);
        this.state = {
            rowMarginTop: "",
            rowPaddingTop: "",
            rowPaddingBottom: "",
            titleMarginRight: "",
            imageMarginTop: "",
            contentRowTop: "",
            publicSaleHeaderTop: "",
            publicSaleHeaderRight: "",
            publicSaleContentTop: "",
            publicSaleContentLeft: "",
            totalSupplyTop: "",
            totalSupplyLeft: "",
            allocationTop: "",
            allocationLeft: "",
            allocationMarginTop: "",
            fontSizeH3: "",
            fontSizeP: "",
            heightP: "",
        };
        this.imageRef = createRef();
    }

     public componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#tokenomics-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));

        window.addEventListener("resize", this.handleResize);
        // Initialise component scaling
        this.handleResize();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);
    }

    public render(): ReactNode {
        return (
            <div id="tokenomics-row-background" style={{
                marginTop: this.state.rowMarginTop,
                paddingTop: this.state.rowPaddingTop,
                paddingBottom: this.state.rowPaddingBottom,
            }}>
                <div className="container">
                    <div id="tokenomics-row">
                        <div id="tokenomics-header-and-image" className="hidden">
                            <h2 className="rgb-orange" style={{ textAlign: "center", marginRight: this.state.titleMarginRight }}>Token Allocation</h2>
                            <img ref={this.imageRef} src={tokenomics} alt="" style={{ marginTop: this.state.imageMarginTop, position: "relative", zIndex: "-1" }} />
                        </div>
                        <div id="tokenomics-content-row" style={{ position: "relative", top: this.state.contentRowTop }}>
                            <div>
                                <div className="hidden" style={{
                                    position: "relative",
                                    top: this.state.publicSaleHeaderTop,
                                    right: this.state.publicSaleHeaderRight,
                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                }}>
                                    <h3 className="rgb-white" style={{ fontSize: this.state.fontSizeH3 }}>75%</h3>
                                    <h3 className="rgb-white" style={{ fontSize: this.state.fontSizeH3 }}>PUBLIC SALE</h3>
                                </div>
                                <div className="hidden" style={{
                                    position: "relative",
                                    top: this.state.publicSaleContentTop,
                                    right: this.state.publicSaleContentLeft,
                                    whiteSpace: "nowrap",
                                }}>
                                    <p className="rgb-white" style={{ fontSize: this.state.fontSizeP, height: this.state.heightP }}>- FAIR LAUNCH</p>
                                    <p className="rgb-white" style={{ fontSize: this.state.fontSizeP, height: this.state.heightP }}>- NO ICO</p>
                                    <p className="rgb-white" style={{ fontSize: this.state.fontSizeP, height: this.state.heightP }}>- NO PRIVATE SALES</p>
                                    <p className="rgb-white" style={{ fontSize: this.state.fontSizeP, height: this.state.heightP }}>- LP TOKENS LOCKED</p>
                                </div>
                            </div>
                            <div className="hidden" style={{
                                position: "relative",
                                top: this.state.totalSupplyTop,
                                left: this.state.totalSupplyLeft,
                                textAlign: "center",
                                whiteSpace: "nowrap",
                            }}>
                                <p className="rgb-black" style={{ fontWeight: "bold", fontSize: this.state.fontSizeP, height: this.state.heightP }}>5 BILLION</p>
                                <p className="rgb-black" style={{ fontSize: this.state.fontSizeP, height: this.state.heightP }}>Total Supply</p>
                            </div>
                            <div className="hidden" style={{
                                position: "relative",
                                top: this.state.allocationTop,
                                left: this.state.allocationLeft,
                                whiteSpace: "nowrap",
                            }}>
                                <h3 className="rgb-black" style={{ fontSize: this.state.fontSizeH3 }}>10% Founders</h3>
                                <h3 className="rgb-black" style={{ marginTop: this.state.allocationMarginTop, fontSize: this.state.fontSizeH3 }}>5% Development</h3>
                                <h3 className="rgb-black" style={{ marginTop: this.state.allocationMarginTop, fontSize: this.state.fontSizeH3 }}>5% Ambassador Program</h3>
                                <h3 className="rgb-black" style={{ marginTop: this.state.allocationMarginTop, fontSize: this.state.fontSizeH3 }}>5% Air Drops</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private calculateRowMarginTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return "-130px";
        }

        return Math.max(-130, linearInterpolateY(screenWidth, {x: 492, y: -130}, {x: 1100, y: -130})) + "px";
    }

    private calculateRowPaddingTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return "400px";
        }

        return Math.min(400, linearInterpolateY(screenWidth, {x: 492, y: 220}, {x: 1100, y: 400})) + "px";
    }

    private calculateRowPaddingBottom(screenWidth: number): string {
        if (screenWidth > 1596) {
            return "0px";
        }

        if (screenWidth > 1100) {
            return linearInterpolateY(screenWidth, {x: 1100, y: 140}, {x: 1596, y: 0}) + "px";
        }

        return "120px";
    }

    private calculateTitleMarginRight(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(120, linearInterpolateY(screenWidth, { x: 1101, y: 94 }, { x: 1600, y: 120 })) + "px";
        }

        return Math.min(94, linearInterpolateY(screenWidth, {x: 492, y: 46}, {x: 1100, y: 94})) + "px";
    }

    private calculateImageMarginTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.max(-110, linearInterpolateY(screenWidth, {x: 1101, y: -92}, {x: 1600, y: -110})) + "px";
        }

        return Math.max(-92, linearInterpolateY(screenWidth, {x: 492, y: -32}, {x: 1100, y: -92})) + "px";
    }

    private calculateContentRowTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.max(-630, linearInterpolateY(screenWidth, {x: 1101, y: -434}, {x: 1600, y: -630})) + "px";
        }

        return Math.max(-434, linearInterpolateY(screenWidth, {x: 492, y: -194}, {x: 1100, y: -434})) + "px";
    }

    private calculatePublicSaleHeaderTop(screenWidth: number): string {
        return Math.min(180, linearInterpolateY(screenWidth, {x: 1101, y: 124}, {x: 1600, y: 180})) + "px";
    }

    private calculatePublicSaleHeaderRight(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(286, linearInterpolateY(screenWidth, {x: 1101, y: 200}, {x: 1600, y: 286})) + "px";
        }

        return Math.min(200, linearInterpolateY(screenWidth, {x: 492, y: 88}, {x: 1100, y: 200})) + "px";
    }

    private calculatePublicSaleContentTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(194, linearInterpolateY(screenWidth, {x: 1101, y: 130}, {x: 1600, y: 194})) + "px";
        }

        return Math.min(130, linearInterpolateY(screenWidth, {x: 492, y: 54}, {x: 1100, y: 130})) + "px";
    }

    private calculatePublicSaleContentRight(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(286, linearInterpolateY(screenWidth, {x: 1101, y: 198}, {x: 1600, y: 286})) + "px";
        }

        return Math.min(198, linearInterpolateY(screenWidth, {x: 492, y: 88}, {x: 1100, y: 198})) + "px";
    }

    private calculateTotalSupplyTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(312, linearInterpolateY(screenWidth, {x: 1101, y: 210}, {x: 1600, y: 312})) + "px";
        }

        return Math.min(210, linearInterpolateY(screenWidth, {x: 492, y: 88}, {x: 1100, y: 210})) + "px";
    }

    private calculateTotalSupplyLeft(screenWidth: number): string {
        return Math.min(12, linearInterpolateY(screenWidth, {x: 1101, y: 8}, {x: 1600, y: 12})) + "px";
    }

    private calculateAllocationTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(67, linearInterpolateY(screenWidth, {x: 1101, y: 46}, {x: 1600, y: 67})) + "px";
        }

        return Math.min(46, linearInterpolateY(screenWidth, {x: 492, y: 20}, {x: 1100, y: 46})) + "px";
    }

    private calculateAllocationLeft(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(270, linearInterpolateY(screenWidth, {x: 1101, y: 188}, {x: 1600, y: 270})) + "px";
        }

        return Math.min(188, linearInterpolateY(screenWidth, {x: 492, y: 85}, {x: 1100, y: 188})) + "px";
    }

    private calculateAllocationMarginTop(screenWidth: number): string {
        if (screenWidth > 1100) {
            return Math.min(100, linearInterpolateY(screenWidth, {x: 1101, y: 70}, {x: 1600, y: 100})) + "px";
        }

        return Math.min(70, linearInterpolateY(screenWidth, {x: 492, y: 30.5}, {x: 1100, y: 70})) + "px";
    }

    private calculateFontSizeH3(imageWidth: number): string {
        return (imageWidth / 48) + "px";
    }

    private calculateFontSizeP(imageWidth: number): string {
        return (imageWidth / 65.45) + "px";
    }

    private calculateHeightP(fontSize: string): string {
        // Ratio of 22px font size to 35px height
        return ((35 / 22) * Number(fontSize.replace("px", ""))) + "px";
    }
}
